<template>
  <div class="section vendorregistration">
    <div class="container">
      <div class="columns is-centered is-vcentered">
        <div class="column is-three-fifths">
          <div class="form-box">
            <h3 class="has-text-centered">Vendor Registration</h3>
            <b-steps :has-navigation="false" :mobile-mode="null" :value="activeStep">
              <b-step-item label="Validation" icon="account-key" :clickable="false"></b-step-item>
              <b-step-item label="Profile" icon="account" :clickable="false"></b-step-item>
              <b-step-item
                label="Confirmation"
                icon="account-plus"
                :clickable="false"
              ></b-step-item>
            </b-steps>
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
              <transition name="fade" mode="out-in" appear>
                <div v-if="status === STATUS_INIT" class="has-responsive-type has-text-centered">
                  <h4>Initializing...</h4>
                </div>
                <div
                  v-else-if="status === STATUS_LOADING"
                  class="has-responsive-type has-text-centered"
                >
                  <h4>Loading...</h4>
                </div>
                <div
                  v-else-if="status === STATUS_SUBMITTED"
                  class="has-responsive-type has-text-centered"
                >
                  <h4>Processing...</h4>
                </div>
                <div v-if="status === STATUS_CODE_VALID" class="has-responsive-type">
                  <h4>Please start by verifying your Vendor Name.</h4>
                  <div class="card">
                    <div class="card-content has-text-centered">
                      <h5>{{ validVendor.vendorName || '' }}</h5>
                      <h6 v-if="validVendor.address && validVendor.address != undefined">
                        {{ validVendor.address.addressOne || '' }}
                        <br />
                        {{ validVendor.address.city || '' }}, {{ validVendor.address.state || '' }}
                        {{ validVendor.address.postalCode || '' }}
                      </h6>

                      <button class="button is-primary is-fullwidth is-medium" @click="verify">
                        Yes. This is me.
                      </button>
                    </div>
                  </div>
                </div>

                <form
                  v-else-if="status === STATUS_VERIFIED"
                  @submit.prevent="handleSubmit(onFormSubmit)"
                  class="form"
                >
                  <h4>Now, let's setup your account.</h4>

                  <fieldset>
                    <div class="form-grid">
                      <div class="column is-12">
                        <h5>
                          Please confirm your name, email, and password.
                        </h5>
                      </div>
                      <div class="column is-6">
                        <valid-input
                          type="text"
                          vid="firstName"
                          placeholder="First Name"
                          rules="required"
                          autocomplete="off"
                          v-model="formData.firstName"
                        />
                      </div>
                      <div class="column is-6">
                        <valid-input
                          type="text"
                          vid="lastName"
                          placeholder="Last Name"
                          rules="required"
                          autocomplete="off"
                          v-model="formData.lastName"
                        />
                      </div>
                      <div class="column is-12">
                        <valid-input
                          type="email"
                          vid="email"
                          placeholder="Email"
                          icon="email"
                          rules="required|email"
                          v-model="formData.email"
                        />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset>
                    <div class="form-grid">
                      <div class="column">
                        <valid-input
                          name="password"
                          vid="password"
                          type="password"
                          placeholder="Password"
                          icon="lock"
                          rules="required|min:6"
                          v-model="formData.password"
                        />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset>
                    <ValidationProvider vid="terms" rules="required" v-slot="{ errors }">
                      <div class="field">
                        <b-checkbox v-model="formData.agreeToTerms" required>
                          I agree to the Terms &amp; Conditions
                        </b-checkbox>
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </fieldset>
                  <button
                    type="submit"
                    class="button is-primary is-fullwidth"
                    :disabled="status === STATUS_SUBMITTED"
                  >
                    Submit Registration
                  </button>
                </form>
                <div
                  v-else-if="status === STATUS_COMPLETE"
                  class="has-responsive-type has-text-centered"
                >
                  <h4>
                    Thank you for registering.
                  </h4>
                  <div class="content">
                    <p>Your information has been received.</p>
                    <p>
                      Please check your email for a confirmation link.
                    </p>
                  </div>
                </div>
                <div
                  v-else-if="status === STATUS_ERROR"
                  class="has-responsive-type has-text-centered"
                >
                  <h4>An error has occured.</h4>
                  <p>{{ errorMsg }}</p>
                </div>
              </transition>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import { ValidationObserver, ValidationProvider } from 'vee-validate'

// services
import registration from '@/services/registration'
import { ValidationError } from '@/services/errors'
import { notifyError } from '@/services/notify'
//import parseAddress from '@/utilities/address/parse'
import { appId } from '@/config'
appId('vendor')

// components
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'

let DEFAULT_FORM_VALUES = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  agreeToTerms: true
}

const STATUS_ERROR = -1
const STATUS_INIT = 0
const STATUS_LOADING = 1
const STATUS_CODE_VALID = 2
const STATUS_VERIFIED = 3
const STATUS_SUBMITTED = 4
const STATUS_COMPLETE = 5

export default {
  components: {
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider
  },

  data: function() {
    return {
      isDebug: true,
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      validVendor: null,
      errorMsg: null,
      status: STATUS_INIT,
      activeStep: 0,

      STATUS_ERROR,
      STATUS_INIT,
      STATUS_LOADING,
      STATUS_CODE_VALID,
      STATUS_VERIFIED,
      STATUS_SUBMITTED,
      STATUS_COMPLETE
    }
  },

  async mounted() {
    this.resetForm()

    const { code } = this.$route.params
    this.errorMsg = ''

    this.status = STATUS_LOADING

    if (this.$route.query.email && this.$route.query.email != undefined) {
      this.formData.email = this.$route.query.email
    }

    if (code) {
      try {
        this.validVendor = await registration.ValidateVendorSignupCode(code)
        this.status = STATUS_CODE_VALID
      } catch (e) {
        this.error('This link is not valid. The sign up code may have expired.')
      }
    }
  },

  methods: {
    error(msg) {
      this.status = STATUS_ERROR
      this.errorMsg = msg
    },

    resetForm() {
      this.formData = { ...DEFAULT_FORM_VALUES }
      this.activeStep = 0
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
    },

    verify() {
      this.status = STATUS_VERIFIED
      // try to get first and last name from code result
      this.formData.firstName = this.validVendor.name
      this.activeStep = 1
    },

    getFormPayload() {
      const payload = { ...this.formData }
      const { code } = this.$route.params
      payload.signupCode = code
      return payload
    },

    async onFormSubmit() {
      try {
        this.status = STATUS_SUBMITTED
        const results = await registration.RegisterVendor(this.getFormPayload())
        if (results) {
          this.status = STATUS_COMPLETE
          this.activeStep = 2
        } else {
          notifyError('There was a problem with your vendor registration.')
          this.errorMsg = 'There was a problem with your vendor registration.'
          this.status = STATUS_ERROR
        }
      } catch (e) {
        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        } else {
          // Default. Pass error message to last field on the form
          this.$refs.form.setErrors({
            password: [e.message]
          })
        }
      }
      this.loading = false
    }
  }
}
</script>
